import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue') 
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue') 
  },
  {
    path: '/order_bandom',
    name: 'Bandom',
    component: () => import('../views/Bandom.vue') 
  },
  {
    path: '/bandom_product',
    name: 'BandomProduct',
    component: () => import('../views/BandomProduct.vue') 
  },
  {
    path: '/special_store',
    name: 'SpecialStore',
    component: () => import('../views/SpecialStore.vue') 
  },
  {
    path: '/special_product',
    name: 'SpecialProduct',
    component: () => import('../views/SpecialProduct.vue') 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
