<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      color="white"
    >
      <v-btn
        @click="goCartHome"
        icon
        :style="get_btn_size"
        v-show="this.$route.path !== '/'"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        @click="goBack"
        icon
        :style="get_btn_size"
        v-show="isAPP"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-img
          alt="Logo"
          src="https://cheerlifeticketresource.s3-ap-northeast-1.amazonaws.com/group_add_image/banner.png"
          transition="scale-transition"
          max-height="20"
          max-width="200"
          contain
        />
      <v-spacer></v-spacer>
      <!-- 前往購物車頁面 icon -->
      <v-btn
        @click="goPath('/cart')"
        icon
        class="btn_margin"
        :style="get_btn_size"
        v-show="is_product && this.$route.path !== '/cart'"
      >
        <v-badge
          :content="get_cart_num"
          :value="get_cart_num"
          color="green"
          overlap
        >
          <v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <!-- login -->
    <div v-if="not_login">
      <v-dialog
        v-model="login_dialog"
        width="500"
        persistent
      >
        <v-stepper v-model="login_info.el">
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="mb-3">
                <v-card-title class="headline">{{$t('ProductInfoShop_title2')}}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="login_info.mobile"
                    type="number"
                    :label="$t('phone_input')"
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="checkUserState()"
              >
                {{$t('next_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="mb-3">
                <v-card-title class="headline">{{$t('ProductInfoShop_title3')}}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="login_info.mobile"
                    type="number"
                    :label="$t('phone_input')"
                  ></v-text-field>
                  <div class="text-center mb-5">
                    <v-btn
                      color="error"
                      @click="SendSMSCode()"
                      :disabled="sms_btn.smsbtndisable"
                    >
                      {{$t('ProductInfoShop_button4')}}
                      <span
                        class="pl-2"
                        v-show="sms_btn.smsbtndisable"
                      >
                        {{ sms_btn.smsCountDown }}{{$t('ProductInfoShop_text4')}}
                      </span>
                    </v-btn>
                  </div>
                  <v-text-field
                    v-model="login_info.sns"
                    :label="$t('ProductInfoShop_input2')"
                    outlined
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="CheckSMSCode()"
              >
                {{$t('doubleCheck_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <div class="mb-3">
                <v-card-title class="headline">{{ set_password_tittle }}</v-card-title>
                <!-- <v-card-subtitle>需4位以上字母</v-card-subtitle> -->
                <v-card-text>
                  <v-text-field
                    :append-icon="show_area.password1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area.password1 ? 'text' : 'password'"
                    @click:append="show_area.password1 = !show_area.password1"
                    v-model="login_info.newpassword"
                    :label="$t('password_input')"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="show_area.password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area.password2 ? 'text' : 'password'"
                    @click:append="show_area.password2 = !show_area.password2"
                    v-model="login_info.checknewpassword"
                    :label="$t('doubleCheckPassword_input')"
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="SendPassword()"
              >
                {{$t('sendPassword_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <div>
                <v-card-title class="headline">{{$t('ProductInfoShop_title4')}}</v-card-title>
                <v-card-text class="mb-n5">
                  <v-text-field
                    v-model="login_info.mobile"
                    :label="$t('mobile_input')"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="show_area.password3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area.password3 ? 'text' : 'password'"
                    @click:append="show_area.password3 = !show_area.password3"
                    v-model="login_info.password"
                    :label="$t('password_input')"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="ForgetPassword()">{{$t('forgetPassword_button')}}</v-btn>
                </v-card-actions>
              </div>
              <v-btn
                color="primary"
                @click="SendLogin()"
              >
                {{$t('send_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </div>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    is_product: false,
    cart_num: 0,
    // login
    login_info: {
      el: 1,
      mobile: '',
      sns: '',
      newpassword: '',
      checknewpassword: '',
      token: '',
      user_uuid: '',
      password: '',
      cheer_point: null,
    },
    login_dialog: false,
    not_login: true,
    show_area: {
      password1: false,
      password2: false,
      password3: false,
    },
    // 簡訊按鈕倒數
    sms_btn: {
      smsCountDown: 180,
      smsbtndisable: false,
    },
    // 設定密碼文字
    set_password_tittle: '請設定密碼,作為下次登入使用',
    // 登入後倒去頁面
    toPathName: ''
  }),
  computed: {
    get_cart_num() {
      if (this.$store.state.putcartlist.putcartData !== undefined) {
        return this.$store.state.putcartlist.putcartData.length;
      } else {
        return 0;
      }
    },
    get_btn_size() {
      const width = window.screen.width;
      if (width < 375 && this.$route.path === '/product') {
        return `height: 35px; width: 35px;`
      } else {
        return '';
      }
    },
    // 首頁且在app
    isAPP() {
      if (navigator.userAgent.indexOf('cheerlife') !== -1) {
        if (this.$route.path === '/') {
          const referrer = document.referrer;
          const last = sessionStorage.getItem('last');
          if (referrer !== '' && last !== '/product') {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    directProduct() {
      if (this.$store.state.putcartlist.putcartData !== undefined) {
        this.is_product = true;
      } else {
        this.is_product = false;
      }
    },
    // 返回首頁
    goCartHome() {
      const store_uuid = sessionStorage.getItem('storeUUID');
      const IsSpecialStore = sessionStorage.getItem('IsSpecialStore');
      console.log(IsSpecialStore);
      if (IsSpecialStore !== undefined && IsSpecialStore == 'true') {
        if (this.$route.query.phone !== undefined) {
          this.$router.push(`/special_store?phone=${this.$route.query.phone}&store=${store_uuid}`);
        } else {
          this.$router.push(`/special_store?store=${store_uuid}`);
        }
      } else{
        if (this.$route.query.phone !== undefined) {
          this.$router.push(`/?phone=${this.$route.query.phone}&store=${store_uuid}`);
        } else {
          this.$router.push(`/?store=${store_uuid}`);
        }
      }
    },
    // app內回上一頁
    goBack() {
      const ask = confirm(this.$t('App_text1'));
      if (ask) {
        history.go(-1);
      } else {
        return false;
      }
    },
    // 前往購物車/購物金
    goPath(url) {
      // 已登入
      if (
        this.readCookie('accessToken') !== '' &&
        this.readCookie('accessToken') !== null &&
        this.readCookie('user_uuid') !== '' &&
        this.readCookie('user_uuid') !== null
      ) {
        sessionStorage.setItem('accessToken', this.readCookie('accessToken'));
        sessionStorage.setItem('user_uuid', this.readCookie('user_uuid'));
        // check token 有沒有過期
        const token = this.readCookie('accessToken');
        const uuid = this.readCookie('user_uuid');
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/user-info`,
          headers: {
              Authorization: `Bearer ${token}`
          },
          data: {
              user_uuid: uuid
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.login_dialog = false;
            this.$router.push(url);
          }
        })
        .catch((error) => {
            alert(error.response.data.message);
            if (error.response.data.message === 'token過期') {
                sessionStorage.setItem('accessToken', '');
                sessionStorage.setItem('user_uuid', '');
                document.cookie = 'accessToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'user_uuid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                this.login_dialog = true;
            }
        });
      } else {
        //  判斷是不是從app進入
        if (navigator.userAgent.indexOf('cheerlife') !== -1) {
          const get_phone = this.$route.query.phone;
          if (get_phone !== undefined && get_phone !== '') {
            this.login_info.mobile = this.$route.query.phone;
            // login axios
            this.axios({
              method: 'post',
              url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/get-user-token`,
              data: {
                access_key: process.env.VUE_APP_ACCESSKEY,
                mobile: this.login_info.mobile,
              },
            })
              .then((response) => {
                // console.log(response);
                this.login_info.token = response.data.token;
                this.login_info.user_uuid = response.data.user_uuid;
                sessionStorage.setItem('accessToken', this.login_info.token);
                sessionStorage.setItem('user_uuid', this.login_info.user_uuid);
                sessionStorage.setItem('mobile', this.login_info.mobile);
                // set cookies
                const expire_day = 1;
                const get_day = new Date();
                get_day.setTime(get_day.getTime() + (expire_day * 24 * 60 * 60 * 1000));
                const expires = `expires=${get_day.toGMTString()}`;
                document.cookie = `accessToken=${this.login_info.token}; expires=${expires};`;
                document.cookie = `user_uuid=${this.login_info.user_uuid}; expires=${expires};`;
                document.cookie = `mobile=${this.mobile}; expires=${expires};`;
                this.$router.push(url);
              })
              .catch((error) => {
                alert(error.response.data.message);
              });
          } else {
            this.login_dialog = true;
          }
        } else {
          this.toPathName = url;
          this.login_dialog = true;
        }
      }
    },
    // get cookie
    readCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    },
    // login
    checkUserState() {
      this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/check-user-register`,
        data: {
          access_key: process.env.VUE_APP_ACCESSKEY,
          mobile: this.login_info.mobile,
        },
      })
        .then((response) => {
          this.login_info.user_uuid = response.data.user_uuid;
          if (response.data.message === 'registered') {
            this.login_info.el = 4;
          } else {
            this.SMSCountDownTimer();
            this.login_info.el = 2;
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    dialogCancel() {
      this.login_dialog = false;
    },
    SMSCountDownTimer() {
      if (this.sms_btn.smsCountDown > 0) {
        this.sms_btn.smsbtndisable = true;
        setTimeout(() => {
          this.sms_btn.smsCountDown -= 1
          this.SMSCountDownTimer();
        }, 1000)
      } else {
        this.sms_btn.smsbtndisable = false;
      }
    },
    SendSMSCode() {
      // 發送驗證碼
      this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_LINEPAYAPI}.cheerlife.io/cheerlife/v1/web-users/send-verify`,
        data: {
          access_key: process.env.VUE_APP_LINEPAYAPIACCESSKEY,
          mobile: this.login_info.mobile,
          user_uuid: this.login_info.user_uuid,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert('已發送！！');
            this.sms_btn.smsCountDown = 180;
            this.SMSCountDownTimer();
          } else {
            alert(response.data.message);
            return false;
          }
        })
        .catch((error) => {
          alert(`status code: ${ error.response.status }`);
          alert(error.response.data.message);
        });
    },
    CheckSMSCode() {
      // 確認驗證碼
      this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/check-verify-code`,
        data: {
          access_key: process.env.VUE_APP_ACCESSKEY,
          mobile: this.login_info.mobile,
          code: this.login_info.sns
        },
      })
        .then((response) => {
          if (response.data.message === 'success') {
            this.login_info.token = response.data.token;
            this.login_info.user_uuid = response.data.user_uuid;
            this.login_info.el = 3;
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    SendPassword() {
      // 設定密碼
      if (this.login_info.newpassword !== this.login_info.checknewpassword) {
        alert('密碼需一致');
      } else if (this.login_info.newpassword === '' || this.login_info.checknewpassword === '') {
        alert('密碼不可空值');
      } else {
        const userpassword = this.Base64.encode(this.login_info.newpassword);
        this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/set-password`,
        headers: {
          Authorization: `Bearer ${this.login_info.token}`
        },
        data: {
          user_uuid: this.login_info.user_uuid,
          password: userpassword
        },
      })
        .then((response) => {
          if (response.data.message === 'success') {
            alert('密碼設定成功！！');
            this.login_info.el = 4;
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
      }
    },
    SendLogin() {
      const userpassword = this.Base64.encode(`${this.login_info.mobile}:${this.login_info.password}`);
      // 登入
      this.axios({
        method: 'get',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/login`,
        headers: {
          Authorization: `Basic ${userpassword}`
        }
      })
        .then((response) => {
          this.login_info.token = response.data.token;
          this.login_info.user_uuid = response.data.user_uuid;
          this.login_info.cheer_point = response.data.cheer_point;
          // set sessionstorage
          sessionStorage.setItem('accessToken', this.login_info.token);
          sessionStorage.setItem('user_uuid', this.login_info.user_uuid);
          // set cookies
          const expire_day = 1;
          const get_day = new Date();
          get_day.setTime(get_day.getTime() + (expire_day * 24 * 60 * 60 * 1000));
          const expires = `expires=${get_day.toGMTString()}`;
          document.cookie = `accessToken=${this.login_info.token}; expires=${expires};`;
          document.cookie = `user_uuid=${this.login_info.user_uuid}; expires=${expires};`;
          this.login_dialog = false;
          this.$router.push(this.toPathName);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    ForgetPassword() {
      this.set_password_tittle = this.$t('ProductInfoShop_title5');
      this.login_info.el = 2;
    },
  },
  mounted() {
    this.directProduct();
  },
  updated() {
    this.directProduct();
  },
  watch: {
  },
};
</script>

<style scoped>
  /* .theme--light.v-application {
    background-color: #f5f5f5;
  } */
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child.btn_margin {
    margin-right: 5px;
    /*background: red;*/
  }
  .theme--light.v-application {
    background-color: #F2F2F2;
  }
</style>
