import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    choosestore: '',
    // 商品與數量
    putcartlist: [],
    // 儲存訂購人地址
    orderinfo: [],
    // 儲存收件人地址
    recinfo: [],
    cartlist: [],
    addresslist: [],
    // 儲存付款方式
    payment: [],
    // 選擇付款方式
    paytype: 1,
    // 星幣/折價券/購物金折抵價格
    // 選擇折扣方式 0: 未使用優惠 1:使用序號 2:使用星幣 3:使用購物金
    // 折價券序號
    couponpoint: {
      starprice: 0,
      couponprice: 0,
      shopprice: 0,
      usetype: 0,
      couponinput: '',
    },
    // 運費/商品小計價格/商品最高可折抵星幣
    shippingprice: {}
  },
  mutations: {
    setStoreData(state, storeData) {
      state.choosestore = storeData;
    },
    // 商品與數量
    setPutCartData(state, putcartData) {
      state.putcartlist = putcartData;
    },
    // 後端回傳商品與數量購物車總資訊
    setGetCartData(state, getcartData) {
      state.getcartlist = getcartData;
    },
    // 儲存訂購人綜合地址
    setOrderinfoData(state, orderinfoData) {
      state.orderinfo = orderinfoData;
    },
    // 儲存收件人綜合地址
    setRecinfoData(state, recinfoData) {
      state.recinfo = recinfoData;
    },
    // 儲存訂購資訊
    setUserinfoData(state, userinfoData) {
      state.userinfo = userinfoData;
    },
    // 儲存付款方式
    setPaymentData(state, paymentData) {
      state.payment = paymentData;
    },
    setCartData(state, cartData) {
      state.cartlist = cartData;
    },
    setAddressData(state, addressData) {
      state.addresslist = addressData;
    },
    // 選擇付款方式
    setPayData(state, payData) {
      state.paytype = payData;
    },
    // 折價券序號/折價卷 星幣 購物金折抵價格/選擇折扣方式
    setCouponPointData(state, couponpointData) {
      state.couponpoint = couponpointData;
    },
    // 運費/商品小計價格
    setShippingPriceData(state, shippingpriceData) {
      state.shippingprice = shippingpriceData;
    },
  },
  actions: {
  },
  modules: {
  }
})
