import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import liff from "@line/liff"
import VueI18n from 'vue-i18n'
import zh from './lang/tw'
import en from './lang/en'

Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.prototype.$liff = liff;
Vue.prototype.Base64 = require('js-base64').Base64;
Vue.prototype.$Aftee = window.Aftee;
Vue.config.productionTip = false

// 預設使用的語系
let locale = 'zh';
const userLang = navigator.language || navigator.userLanguage;
if (userLang.indexOf('TW') === -1 && userLang.indexOf('tw') === -1) {
  locale = 'en'
} else {
  locale = 'zh'
}
const i18n = new VueI18n({
  locale: locale,
  messages: {
    'zh': zh,
    'en': en
  }
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('last', from.path);
  next();
})

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
